import Carousel from "./Carousel";

export default function LoginLeft() {
  return (
    <Carousel>
      {(image) => (
        <div
          className="login-left"
          style={{ backgroundImage: `url(${image})` }}
        >
          <div className="login-left-content" />
        </div>
      )}
    </Carousel>
  );
}
