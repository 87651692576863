import { useState, useRef, useEffect } from 'react';
import Image from 'next/image';
import logo from '/public/images/logo-transparent-small.png';
import { useRouter } from 'next/router';
import { Formik } from 'formik'
import * as yup from 'yup';
import { userService } from '@/services/user-service';
import toast from 'react-hot-toast';
import { useDispatch } from 'react-redux';
import { setUser } from '@/redux/actions/userActions';
import FormComponent from '@/components/commons/FormComponent';
import InputEmail from '@/lib/ui/InputEmail';
import InputPassword from '@/lib/ui/InputPassword';
import ButtonSolid from '@/lib/ui/ButtonSolid';
import ButtonOutline from '@/lib/ui/ButtonOutline';
import LoginLeft from '@/components/LoginLeft';

const LoginPage = () => {
    const router = useRouter();
    const dispatch = useDispatch();

    const initialValues = { email: '', password: '' };

    const validationSchema = yup.object().shape({
        email: yup.string().required('Email is required'),
        password: yup.string().required('Password is required')
    });

    const handleError = (message) => {
        toast.error('Error during authentication. ' + message);
    }

    const handleSuccess = (user) => {
        dispatch(setUser(user));
        const home = '/jobs';
        const returnUrl = router.query.returnUrl || home;
        router.push(returnUrl.includes('[') ? home : returnUrl);
        // if (user.status !== 'active') {
        //     router.push('/register?action=inactive');
        // } else {
        //     dispatch(setUser(user)); 
        //     const returnUrl = router.query.returnUrl || '/';
        //     router.push(returnUrl);
        // }
    }

    const handleLogin = async (values, actions) => {
      try {
        const { email, password } = values;
        const response = await userService.login(email, password);

        if (response.error) {
          toast.error(`Error during Authentication. ${response.message}`);
        }

        if (response.success) {
          handleSuccess(response.user);
        }
      } catch (error) {
        const user = localStorage.getItem('hguser');

        if (error.name === "TypeError" && user) {
          handleSuccess(JSON.parse(user));
        }
      }
    };

    const handleRegister = () => {
        router.push('/register');
    };

    const handleForgotPassword = () => {
        router.push('/forgot-password');
    }

    return (
        <div className="login-container">
            <LoginLeft />
            <div className="login-right">
                <div className="login-right-content">
                    <Image src={logo} />
                    <div className="login-welcome">G'day!</div>
                    <div className="login-welcome-text">Sign in to start building superb, fully customised soil & plant nutrient programs to help food producers realise their dreams and to put more nutrient dense food into the world.</div>
                    <Formik onSubmit={handleLogin} initialValues={initialValues} validationSchema={validationSchema}>
                        {props => (
                            <form onSubmit={props.handleSubmit} autoComplete="off">
                                <div className="mt-12">
                                    <InputEmail
                                        name="email" 
                                        value={props.values.email}
                                        onChange={props.handleChange}
                                        label="Email" 
                                        placeholder="youremail@gmail.com"
                                        setFieldValue={props.setFieldValue}
                                        errors={props.touched.email && props.errors.email ? props.errors.email : undefined} />
                                </div>
                                <div className="mt-12">
                                    <InputPassword 
                                        name="password" 
                                        value={props.values.password}
                                        label="Password"
                                        placeholder="****************"
                                        onChange={props.handleChange}
                                        errors={props.touched.password && props.errors.password ? props.errors.password : undefined} />
                                </div>
                                <div className="flex flex-row-reverse pt-5 px-2">
                                    <button className="login-forgot-password" type="button" onClick={handleForgotPassword}>Forgot Password?</button>
                                </div>
                                <div className="flex flex-row pt-8">
                                    <ButtonSolid className="font-bold mr-6" label="Login" type="submit" isSubmitting={!props.isValidating && props.isSubmitting} />
                                    <ButtonOutline className="font-bold text-sm" label="Register" type="button" onClick={handleRegister} />
                                </div>
                            </form>
                        )}
                    </Formik>
                </div>
            </div>
        </div>
    );
}

export default LoginPage;