import { useEffect } from "react";
import { useMemo } from "react";

export default function Carousel({ children }) {
  const key = "carouselIndexes";

  const carouselIndexes = JSON.parse(localStorage.getItem(key)) ?? [];

  const images = [
    "/images/carousel/apple.jpg",
    "/images/carousel/cherry-orchard.jpg",
    "/images/carousel/grapes.jpg",
    "/images/carousel/lettuce.jpg",
  ];

  const index = useMemo(() => {
    let index;

    do {
      index = Math.floor(Math.random() * images.length);
    } while (carouselIndexes.includes(index));

    return index;
  }, []);

  useEffect(() => {
    localStorage.setItem(
      key,
      JSON.stringify([carouselIndexes[carouselIndexes.length - 1], index])
    );
  }, [index]);

  const image = images[index];

  return children(image);
}
